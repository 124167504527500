import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { GuestLayout } from './layouts/GuestLayout';
import { AuthorizedLayout } from './layouts/AuthorizedLayout';

const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Surveys = lazy(() => import('./pages/surveys/Surveys'));
const NewSurvey = lazy(() => import('./pages/surveys/NewSurvey'));

export const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route element={<AuthorizedLayout />}>
        <Route path="surveys">
          <Route path="new" element={<NewSurvey />} />
          <Route index element={<Surveys />} />
        </Route>
      </Route>
      <Route element={<GuestLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  </Suspense>
);
