import React from 'react';
import {
  Button,
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import { Link } from 'react-router-dom';

function SidebarMain() {
  return (
    <div id="sidebar">
      <div className='widget'>
        <div className='widget-head'><Button className='btn-block btn-warning-light' themeColor='warning'>TẠO KHẢO SÁT</Button></div>
      </div>
      <div className='widget'>
        <div className='widget-head'>
          <h3 className='widget-title'>THỂ LOẠI</h3>
          <Button className='widget-head-control' icon="folder" size="small" fillMode="flat" />
        </div>
        <ul className='widget-nav'>
          <li>
            <Link className='widget-nav-item' to={`#`}>
              Home
              <Button className='widget-nav-item-control' icon="folder" size="small" fillMode="flat" />
            </Link>
          </li>
          <li>
            <Link className='widget-nav-item' to={`/surveys`}>Surveys</Link>
            <Button className='widget-nav-item-control' icon="folder" size="small" fillMode="flat" />
          </li>
          <li>
            <Link className='widget-nav-item' to={`/surveys/new`}>New Surveys</Link>
            <Button className='widget-nav-item-control' icon="folder" size="small" fillMode="flat" />
          </li>
        </ul>
      </div>
      <div className='widget'>
        <div className='widget-head'>
          <h3 className='widget-title'>TRẠNG THÁI</h3>
        </div>
        <ul className='widget-nav'>
          <li>
            <Link className='widget-nav-item' to={`/`}>Home</Link>
          </li>
          <li>
            <Link className='widget-nav-item' to={`/surveys`}>Surveys</Link>
          </li>
          <li>
            <Link className='widget-nav-item' to={`/surveys/new`}>New Surveys</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SidebarMain;
