import React from 'react';
import { Outlet } from 'react-router-dom';

export const GuestLayout = () => (
  <div id="wrapper">
    <div id="main">
      <Outlet />
    </div>
  </div>
);
