import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import SidebarMain from './SidebarMain';

export const AuthorizedLayout = () => (
  <div id="wrapper">
    <HeaderMain />
    <div id="main">
      <SidebarMain />
      <Outlet />
    </div>
  </div>
);
