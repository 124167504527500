import React from 'react';
import { Link } from "react-router-dom";
import {
  AppBar,
  AppBarSection,
  Avatar,
} from '@progress/kendo-react-layout';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
let brandImg = '/img/logo.png';
let kendokaAvatar = 'https://www.telerik.com/kendo-react-ui-develop/components/images/kendoka-react.png';
function HeaderMain() {
  return (
    <React.Fragment>
      <AppBar className="k-appbar-primary">
        <AppBarSection className="appbar-brand">
          <Link to={`/`} className="navbar-brand logo-item">
            <img src={brandImg} alt="KendoReact Layout Kendoka Avatar" />
          </Link>
        </AppBarSection>

        <AppBarSection className="appbar-menu">
          <ul className="appbar-nav">
            <li className="appbar-nav-item">
              <Link to={`/surveys/new`} className="appbar-nav-link">Khảo sát của tôi</Link>
            </li>
            <li className="appbar-nav-item">
              <Link to={`/surveys/new`} className="appbar-nav-link">Mẫu khảo sát</Link>
            </li>
            <li className="appbar-nav-item">
              <Link to={`/surveys/new`} className="appbar-nav-link">Mẫu khảo sát</Link>
            </li>
          </ul>
        </AppBarSection>

        <AppBarSection className="appbar-actions">
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <BadgeContainer>
              <span className="k-icon k-i-bell" />
              <Badge
                shape="dot"
                themeColor="info"
                size="small"
                position="inside"
              />
            </BadgeContainer>
          </button>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <BadgeContainer>
              <span className="k-icon k-i-bell" />
              <Badge
                shape="dot"
                themeColor="info"
                size="small"
                position="inside"
              />
            </BadgeContainer>
          </button>
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <BadgeContainer>
              <span className="k-icon k-i-bell" />
              <Badge
                shape="dot"
                themeColor="info"
                size="small"
                position="inside"
              />
            </BadgeContainer>
          </button>
          <span className="k-appbar-separator" />
          <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <BadgeContainer>
              <span className="k-icon k-i-bell" />
              <Badge
                shape="dot"
                themeColor="info"
                size="small"
                position="inside"
              />
            </BadgeContainer>
          </button>
          <span className="k-appbar-separator" />

          <Avatar type="image">
            <img src={kendokaAvatar} alt="KendoReact Layout Kendoka Avatar" />
          </Avatar>
        </AppBarSection>
      </AppBar>
    </React.Fragment>
  );
}

export default HeaderMain;
